import React from "react";
import { about, mission } from "../../assets";
import { servicesData } from "../../data/service";
import { SmallServiceCard } from "../../components/HomeServiceCard";
import { Link } from "react-router-dom";

const Homepage = () => {
	return (
		<>
			<div className="h-[78vh] mb-5  overflow-hidden bg-[#171640] flex flex-col items-center justify-center relative">
				<h1 className="text-5xl font-bold text-white">Arif Studios</h1>
				<div className="flex flex-row justify-bottom items-baseline boder-2 justify-items-end">
					{" "}
					<img
						alt=""
						className="brder-2 border-red-400 w-[20vw]"
						src="https://res.cloudinary.com/dvqawl4nw/image/upload/v1685794568/bpmvdtvd33cjw5ab0xql.png"
					/>
					<p className="text-base text-white ">
						Where Creativity Comes to Life
					</p>
					<img
						alt=""
						className="w-[20vw] boder-2"
						src="https://res.cloudinary.com/dvqawl4nw/image/upload/v1685794568/bpmvdtvd33cjw5ab0xql.png"
					/>
				</div>
				<Link to={`/contactus`}>
					<button className="bg-white rounded-full px-8 mt-8 py-2">
						Contact Us
					</button>
				</Link>
				{/* TODO: Remove this If Not necessary */}
				<div className="scroll-prompt">
					<div className="scroll-cursor"></div>
				</div>
				{/* END TODO: */}
			</div>
			<div className="flex flex-col items-center justify-center">
				<h1 className="mt-6 text-lg tracking-widest text-[#171640]">
					OUR SERVICES
				</h1>
				<p className="text-sm">
					Increase your insight and make the better experience with
					our valuable services.
				</p>
				<div className="grid grid-cols-2 md:flex md:flex-wrap md:justify-center ">
					{servicesData.map((service, index) => (
						<SmallServiceCard key={index} {...service} />
					))}
				</div>
			</div>
			<div className="flex flex-col sm:flex-row text-start items-start justify-start m-5 sm:m-20">
				<div className="sm:flex-[3] p-4">
					<img
						className="w-full h-auto"
						src={mission}
						alt="Mission"
					/>
				</div>

				<div className="sm:flex-[5] p-4 sm:ml-10">
					<h1 className="mt-6 text-lg tracking-widest text-[#171640]">
						OUR MISSION
					</h1>
					<h2 className="text-2xl sm:text-4xl font-sans font-semibold mt-2 pr-4 lg:pr-20">
						Provide our clients with the strategic guidance they
						need to achieve their marketing goals
					</h2>
					<p className="mt-2">
						Create high-quality multimedia content that is engaging,
						informative, and memorable and build lasting
						relationships with our clients based on trust and
						collaboration
					</p>
				</div>
			</div>
			<div className="flex flex-col-reverse md:flex-row text-start items-start justify-start m-4 sm:m-20">
				<div className="flex-1 p-4">
					<h1 className="mt-6 text-lg tracking-widest text-[#171640]">
						ABOUT
					</h1>
					<h2 className="text-2xl sm:text-4xl font-sans font-semibold mt-2 pr-5 lg:pr-20">
						We exceed expectations with innovative and
						cost-effective solutions
					</h2>
					<p className="mt-2">
						We offer creative services worldwide, including media
						production, digital marketing, content creation, studio
						rental, equipment rental, and audio-visual solutions
					</p>
				</div>
				<div className="flex-1 p-4">
					<img className="w-full h-auto" src={about} alt="About" />
				</div>
			</div>
		</>
	);
};

export default Homepage;

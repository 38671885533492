import React from "react";
import { Home } from "../assets/index";
import Map from "../components/Map";
import ContactForm from "../components/ContactForm";
import { Map2 } from "../assets";

export default function ContactUs() {
	const googleMapsEmbedUrl =
		"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3102.7697478312594!2d-77.05240119999999!3d39.009417799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c8d4951a5cc9%3A0xa3c1ed59d127c93b!2s2426%20Linden%20Ln%2C%20Silver%20Spring%2C%20MD%2020910%2C%20USA!5e0!3m2!1sen!2s!4v1634126518364!5m2!1sen!2s";

	return (
		<>
			<div className="flex flex-col">
				<div className="w-full mx-auto flex flex-col pb-32">
					<Map />

					<div className="w-full justify-center mx-auto flex flex-wrap gap-6">
						{/* <img
              className="w-[450px] sm:w-[600px] md:w-[700px] lg:w-[500px] "
              src={Home}
              alt=""
            /> */}
						<img
							className="w-[100%] h-auto sm:w-[80%] sm:h-[400px] object-cover "
							src={Home}
							alt=""
						/>
					</div>

					<div className="mt-10 flex flex-col gap-3">
						<h1 className="text-[#FF0049] text-2xl">Studio</h1>
						<p className="text-gray-900">
							2426 Linden Ln, Silver Spring, Maryland 20910, US
						</p>
						<p className="text-gray-900">
							+1 2027510172 • info@arifstudios.com
						</p>
					</div>

					<div className="mt-10 flex flex-col lg:flex-row justify-center items-center lg:gap-8">
						<ContactForm />

						<div>
							<div className="w-full flex flex-col justify-center items-center mx-auto">
								{/* <Map
                  className="my-6 w-[300px] justify-center sm:w-[600px] md:w-[825px] lg:w-[500px] mx-auto"
                  alt=""
                /> */}

								<iframe
									className="my-6 w-[300px] justify-center sm:w-[600px] md:w-[825px] lg:w-[500px] mx-auto"
									title="Google Maps Location"
									width="600"
									height="450"
									loading="lazy"
									src={googleMapsEmbedUrl}
								></iframe>

								<p className="text-start w-[300px] sm:w-[600px] flex flex-col items-center justify-center lg:w-[500px]">
									Our studio enjoys a prime location near the
									Metro and I-495. Just inside the Capital
									Beltway, via Georgia Avenue south, it offers
									exceptional convenience. Only a mile from
									Forest Glen Metro and Chevy Chase, and a
									quick 5-mile drive to Downtown Bethesda.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

import React, { useState } from "react";
import { navLogo } from "../assets";
import { Link, NavLink } from "react-router-dom";
import {
	FaBarsStaggered,
	FaBoxOpen,
	FaSquareArrowUpRight,
	FaX,
} from "react-icons/fa6";
import "./Navbar.css";

const Navbar = () => {
	const [isMobileNavOpened, setMobileNavOpened] = useState(false);

	return (
		<>
			<nav className="flex flex-row items-center justify-between w-full min-h-[12vh] bg-[#171640] text-white p-4 md:px-20 sticky top-0 z-10">
				{/* <div className="rgb(23 22 64 / var(--tw-bg-opacity))"> */}
				<Link to="/">
					{" "}
					<img
						className="w-[70px] h-[70px] sm:w-[80px] sm:h-[80px] "
						src={navLogo}
						style={{ marginLeft: -3 }}
						alt=""
					/>
				</Link>
				{/* </div> */}
				<ul className="items-center space-x-2 sm:space-x-4 justify-center hidden md:flex">
					{links.map((link, index) => (
						<NavLink
							key={index}
							className="cursor-pointer border-b-2 border-transparent transition-all duration-300 ease-in-out hover:border-white [&.active]:border-white"
							to={link[1]}
						>
							{link[0]}
						</NavLink>
					))}
					<li>
						<RentStudioButton />
					</li>
					<li>
						<Link
							className="[&.active]:bg-red-300"
							to={`/contactus`}
						>
							<button className="bg-transparent text-white border-2 rounded-full px-2 md:px-4 sm:mr-9 py-1">
								Contact Us
							</button>
						</Link>
					</li>
				</ul>

				<button
					className="p-3 rounded-md text-[white] border-2 block md:hidden"
					onClick={() => setMobileNavOpened((val) => !val)}
				>
					{isMobileNavOpened ? <FaX /> : <FaBarsStaggered />}
				</button>
				{/* {isMobileNavOpened && (
					<div
						className={`fixed w-full h-full top-0 left-0  md:hidden transition-all duration-200 ease-out  animate-[fadeIn]  `}
					>
						<div className="relative w-full h-full">
							<div
								className="absolute top-0 bottom-0 left-0 right-0 bg-black block opacity-60"
								onClick={() => setMobileNavOpened(false)}
							></div>
						</div>
					</div>
				)} */}
				{isMobileNavOpened && (
					<MobileNavbar onClose={() => setMobileNavOpened(false)} />
				)}
			</nav>
		</>
	);
};

const MobileNavbar = ({ onClose }) => {
	return (
		<div className="fixed top-[100px] left-0 w-full h-full flex">
			{/* Cover */}
			<div
				className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
				onClick={onClose}
			></div>
			<div className="absolute top-0 right-0 bottom-0 flex bg-[white] text-[#171640] shadow-md w-[60%]  flex-col p-5 px-10 items-start navbar__drawer">
				{links.map((link, index) => (
					<NavLink
						key={index}
						className="cursor-pointer border-b-2 border-transparent transition-all duration-300 ease-in-out hover:border-white [&.active]:border-white mb-4"
						onClick={onClose}
						to={link[1]}
					>
						{link[0]}
					</NavLink>
				))}
				<RentStudioButton />

				<button className="bg-transparent text-black  border-2 border-[#171640] rounded-full px-2 md:px-4 sm:mr-9 py-1 mt-3">
					<Link className="[&.active]:bg-red-300" to={`/contactus`}>
						Contact Us
					</Link>
				</button>
			</div>
		</div>
	);
};

export const RentStudioButton = () => {
	return (
		<a
			className="[&.active]:bg-red-300"
			href={`http://studio.arifstudios.com`}
		>
			<button className="bg-[#ff0049] hover:bg-[#dd0049] hover:border-[#dd0049] transition-all duration-200 text-white border-2 border-[#ff0049] rounded-full px-2 md:px-4 sm:mr-1 py-1 flex items-center gap-2">
				<span>Rent Studio</span>
				<span>
					<FaSquareArrowUpRight />
				</span>
			</button>
		</a>
	);
};

const links = [
	["Home", "/"],
	["Services", "/services"],
	["Portfolio", "/portfolio"],
];

export default Navbar;

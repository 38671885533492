import React from "react";
import Logo from "../assets/logo.png";
import {
	BiLogoFacebook,
	BiLogoTwitter,
	BiLogoInstagram,
	BiLogoLinkedin,
} from "react-icons/bi";

export default function Footer() {
	return (
		<div>
			<footer className="bg-gray-200 pt-10" id="footer">
				<div className="mx-auto grid max-w-screen-lg gap-y-8 px-4 py-1  md:grid-cols-1 xl:grid-cols-2  mb-10">
					<div className="max-w-sm">
						<div className="mb-6 flex h-10 items-center space-x-2">
							<img
								className="h-full object-contain"
								src={Logo}
								alt=""
							/>
							<span className="text-lg font-medium">
								Arif studios
							</span>
						</div>
						<div className="text-gray-500 text-start flex flex-col gap-5">
							<p>
								a dynamic multimedia company with bases in
								Silver Spring, Maryland, and Ethiopia, is your
								ultimate partner for captivating creative
								services worldwide
							</p>

							<div className="flex gap-5">
								<a
									href="https://www.facebook.com/Arifstudioss"
									target="_blank"
									className="bg-[#A2BDAA] max-w-fit p-2 rounded-full text-white"
								>
									<BiLogoFacebook />
								</a>

								<a
									href="https://www.instagram.com/arif_studios_/"
									target="_blank"
									className="bg-[#A2BDAA] max-w-fit p-2 rounded-full text-white"
								>
									<BiLogoInstagram />
								</a>

								<a
									href=" https://www.linkedin.com/company/arif-studios/"
									target="_blank"
									className="bg-[#A2BDAA] max-w-fit p-2 rounded-full text-white"
								>
									<BiLogoLinkedin />
								</a>
							</div>
						</div>
					</div>
					<div className="max-w-sm">
						<div className="mb-6 flex h-10 items-center space-x-2">
							<span className="text-lg font-medium">
								Contact Us
							</span>
						</div>
						<div className="text-gray-500 text-start grid grid-cols-1 sm:grid-cols-2 gap-5">
							<div className="flex flex-col">
								<span className="text-sm uppercase">Phone</span>
								<a
									href="tel:+1 2027510172"
									className="font-semibold underline underline-offset-2"
								>
									+1 2027510172
								</a>
							</div>
							<div className="flex flex-col">
								<span className="text-sm uppercase">
									E-mail
								</span>
								<a
									href="mailto:arifstudios@gmail.com"
									className="font-semibold underline underline-offset-2"
								>
									info@arifstudios.com
								</a>
							</div>
							<div className="flex flex-col">
								<span className="text-sm uppercase">
									Address
								</span>
								<a
									href="https://goo.gl/maps/qkHtms9YeEQ949SH9"
									className="font-semibold underline underline-offset-2"
								>
									2426 Linden Ln, Silver Spring, Maryland
									20910, US
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="border-t bg-gray-300 py-2">
					<div className="mx-auto flex max-w-screen-xl flex-col gap-6 px-4 py-3 text-center text-gray-500 sm:px-20  lg:justify-between lg:text-left xl:px-10 ">
						{/* <div className="flex gap-5 justify-center mt-4">
							<p className="bg-black max-w-fit p-2 rounded-full text-white">
								<BiLogoFacebook />
							</p>

							<p className="bg-white border border-black max-w-fit p-2 rounded-full text-black">
								<BiLogoDiscord />
							</p>

							<p className="bg-black max-w-fit p-2 rounded-full text-white">
								<BiLogoInstagram />
							</p>
						</div> */}
						<p className="-order-1 sm:order-none text-center">
							{new Date().getFullYear()}©️ Copyright ArifStudios.
							<br></br>
							<span className="text-sm">All right reserved</span>
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
}

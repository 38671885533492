import React, { useState } from "react";
import { PC, Mixer, Digital, Col, Audio, Media, Branding } from "../assets";
// import { BiChevronRight } from "react-icons/bi";
// import Clients from "./Clients";
// import Modal from "react-modal";
// import { Carousel } from "react-responsive-carousel";
import ImageModal from "./ImageModal";

const portfolioData = [
  {
    title: "audio visuals",
    desc: `Audio Mixing, Audio Mastering, Sound Design, Music Arrangement, Scoring, and Composition, and more we're dedicated to helping you achieve your sonic aspirations, delivering high-quality results that resonate with your audience.  Our passion thrives in crafting multisensory journeys that inspire and evoke emotions.`,
    photoUrl: [Audio, Audio, Audio],
  },
  {
    title: "CONTENT CREATION",
    desc: `Concept Development, Scriptwriting, Video Shooting and Production, Editing and Post-Production, Visual Effects and Animation, Voice Overs and Narration, And More Unleash Creative Brilliance with Our Content Creation Services crafting narratives that captivate and resonate.`,
    photoUrl: [PC, PC, PC],
  },
  {
    title: "MUSIC PRODUCTION",
    desc: `Audio Mixing, Audio Mastering, Sound Design, Music Arrangement, Scoring, and Composition, and more we're dedicated to helping you achieve your sonic aspirations, delivering high-quality results that resonate with your audience.`,
    photoUrl: [Media, Media, Media],
  },
  {
    title: "Digital Marketing",
    desc: `Social Media Management, Content Creation, Web Analytics, Email Marketing Embark on a digital odyssey where creativity meets strategy.`,
    photoUrl: [Digital, Digital, Digital],
  },
  {
    title: "Branding",
    desc: `Logo design, Brand guidelines, brand strategy, and visual identity development. Arif Studios experienced branding professionals collaborate with you to create a brand that truly represents your company and resonates with your clients `,
    photoUrl: [Branding, Branding, Branding],
  },
];

export default function PortfolioSection() {
  return (
    <div className="mt-2 max-w-screen-lg mx-auto mb-16">
      <div className="ml-8 py-8">
        <h1 className="text-center text-[40px]">
          Selected <span className="font-bold"> Works</span>
        </h1>
      </div>
      <div className="flex flex-col gap-16">
        {portfolioData.map((data, index) => (
          <PortfolioItem key={index} {...data} imgLeft={index % 2 === 0} />
        ))}
      </div>
    </div>
  );
}

export const PortfolioItem = ({ title, desc, photoUrl, imgLeft }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div
      className={`flex flex-col overflow-hidden bg-white gap-6 my-6 px-4 sm:${
        imgLeft ? "flex-row-reverse" : "flex-row"
      }`}
    >
      <div className="order-first ml-auto h-48 w-full sm:order-none sm:h-auto sm:w-1/2 lg:w-2/5 px-4">
        <img
          className="object-cover rounded-2xl h-full w-full  px-0"
          src={photoUrl[0]}
          loading="lazy"
          alt={title}
          onClick={openModal}
        />
      </div>
      <div
        className={`flex flex-2 flex-col justify-end text-start sm:w-1/2  lg:w-3/5`}
      >
        <h2 className="text-xl font-bold text-gray-900 md:text-lg uppercase">
          {title}
        </h2>

        <p className="mt-4 mb-5 max-w-md text-md md:text-[20px]  leading-relaxed">
          {desc}
        </p>

        <div className="flex flex-col gap-5">
          <h2 className="text-xl  text-gray-900 md:text-sm uppercase">
            {title}
          </h2>

          {/* <button className="flex items-center border w-fit p-1 rounded-full px-2 bg-[#171640] text-white">
						VIEW WORK <BiChevronRight size={30} />
					</button> */}
        </div>
      </div>

      {modalIsOpen && <ImageModal images={photoUrl} onClose={closeModal} />}
    </div>
  );
};

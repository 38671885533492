import React from "react";
import AwardSection from "../components/AwardSection";
import ServiceSection from "../components/ServicesCards";
import { Link } from "react-router-dom";
import { servicesData } from "../data/service";

export default function OurServices() {
	return (
		<>
			<div className="h-[78vh] mb-5 overflow-hidden bg-[#171640] flex flex-col items-center justify-center relative">
				<h1 className="text-5xl font-bold text-white">Our services</h1>
				<div className="flex flex-row justify-bottom items-baseline mt-4 boder-2 justify-items-end">
					<p className="text-base text-white ">
						FROM CONCEPT TO REALITY
					</p>
				</div>
				<Link to={`/contactus`}>
					<button className="bg-white rounded-full px-8 mt-8 py-2">
						Contact Us
					</button>
				</Link>

				<div className="scroll-prompt">
					<div className="scroll-cursor"></div>
				</div>
			</div>

			<ServiceSection />
		</>
	);
}

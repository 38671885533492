import React, { useState } from "react";
import { db } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import toast from "react-hot-toast";

export default function ContactForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [cellularPhone, setCellularPhone] = useState("");
  const [tellUsMessage, setTellUsMessage] = useState("");

  const handleFormSubmission = async () => {
    // TODO: Add Validation && Error validation && Time Stamp
    /* Below is sample template, update the email, cc and formating of the email, 
	this is just testing the feature discuss with Bese & Desta for any questions */

    const emailTemplate = {
      to: ["info@arifstudios.com"], // Update main email
      cc: [""], //Update cc address confirm with bese
      message: {
        subject: `IMPORTANT: ${firstName} ${lastName} Submitted Form On Site`,
        text: `
NAME:  ${firstName} ${lastName} 
Email:  ${emailAddress} 
Phone: ${cellularPhone} 
Message: ${tellUsMessage} 
		`,
      },
    };

    try {
      await addDoc(collection(db, "mail"), emailTemplate);
      toast.success("Email sent successfully 🚀🚀", {
        duration: 1500,
      });

      // Clear input fields by resetting the state variables
      setFirstName("");
      setLastName("");
      setEmailAddress("");
      setCellularPhone("");
      setTellUsMessage("");
    } catch (error) {
      // Handle any errors that occur during submission
      console.error("Error submitting form:", error);
      toast.error("Error sending email. Please try again later.", {
        duration: 1500,
      });
    }
  };

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col gap-3 justify-center items-center mx-auto">
        <input
          name="firstName"
          value={firstName}
          className="transition-all duration-[300ms] ease-out rounded-lg  appearance-none  py-3 px-5  text-gray-700 placeholder-gray-500 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#FF0049] focus:border-transparent bg-slate-200 w-[300px] sm:w-[450px] lg:w-[300px]"
          type="text"
          placeholder="First Name"
          onChange={(e) => setFirstName(e.target.value)}
        />

        <input
          name="lastName"
          value={lastName}
          className="transition-all duration-[300ms] ease-out rounded-lg  appearance-none  py-3 px-5  text-gray-700 placeholder-gray-500 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#FF0049] focus:border-transparent bg-slate-200 w-[300px] sm:w-[450px] lg:w-[300px]"
          type="text"
          placeholder="Last Name"
          onChange={(e) => setLastName(e.target.value)}
        />

        <input
          name="emailAddress"
          value={emailAddress}
          className="transition-all duration-[300ms] ease-out rounded-lg  appearance-none  py-3 px-5  text-gray-700 placeholder-gray-500 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#FF0049] focus:border-transparent bg-slate-200 w-[300px] sm:w-[450px] lg:w-[300px]"
          type="email"
          placeholder="Email Address"
          onChange={(e) => setEmailAddress(e.target.value)}
        />

        <input
          value={cellularPhone}
          name="cellularPhone"
          className="transition-all duration-[300ms] ease-out rounded-lg  appearance-none  py-3 px-5  text-gray-700 placeholder-gray-500 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#FF0049] focus:border-transparent bg-slate-200 w-[300px] sm:w-[450px] lg:w-[300px]"
          type="tel"
          placeholder="Cellular Phone"
          onChange={(e) => setCellularPhone(e.target.value)}
        />

        <textarea
          name="tellUsMessage"
          value={tellUsMessage}
          className="transition-all duration-[300ms] ease-out rounded-lg  appearance-none  py-3 px-5  text-gray-700 placeholder-gray-500 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#FF0049] focus:border-transparent bg-slate-200 w-[300px] h-auto sm:w-[450px] lg:w-[300px]"
          type="email"
          placeholder="Tell us about ur..."
          onChange={(e) => setTellUsMessage(e.target.value)}
        />

        <div className="py-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              handleFormSubmission();
            }}
            className="transition-all duration-[300ms] ease-out border border-[#FF0049] text-[#FF0049] hover:bg-[#FF0049] hover:text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function ImageModal({ images, onClose }) {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const modalRef = useRef();

  useEffect(() => {
    // Add event listener to close modal when clicking outside of it
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 ${
        modalIsOpen ? "block" : "hidden"
      } overflow-y-auto bg-black bg-opacity-75 flex justify-center items-center z-50`}
    >
      <div
        ref={modalRef}
        className="relative p-4 bg-white rounded-lg"
        style={{ maxWidth: "40%", width: "100%" }}
      >
        <button
          className="absolute top-4 right-4 text-black hover:text-gray-900"
          onClick={closeModal}
        >
          X
        </button>
        <Carousel
          showArrows={true}
          showIndicators={false}
          arrowSize={30}
          arrowThick={3}
        >
          {images?.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`${index}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

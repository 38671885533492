import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import ImageModal from "./ImageModal";
import { servicesData } from "../data/service";
import { RentStudioButton } from "./Navbar";

export default function ServicesSection() {
	return (
		<div className="mt-2 max-w-screen-lg mx-auto mb-16">
			<div className="">
				{servicesData.map((data, index) => (
					<ServicesCards
						key={index}
						{...data}
						imgLeft={index % 2 === 0}
					/>
				))}
			</div>
		</div>
	);
}

export const ServicesCards = ({
	title,
	desc,
	listTitle,
	servicesList,
	image,
	imgLeft,
}) => {
	const [modalOpen, setModalOpen] = useState(false);

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	return (
		<div className="mt-1">
			<div className="m-10 mx-auto max-w-screen-lg overflow-hidden rounded-xl  md:pl-8 flex flex-col gap-20">
				<div
					className={`flex flex-col overflow-hidden bg-white gap-4 my-4 px-4 ${
						imgLeft ? "sm:flex-row-reverse" : "sm:flex-row"
					}`}
				>
					<div className="order-first ml-auto h-48 w-full sm:order-none sm:h-auto sm:w-1/2 lg:w-2/5 px-4">
						<img
							className="h-full order-1 w-full object-cover rounded-2xl"
							src={image[0]}
							loading="lazy"
							alt=""
							onClick={openModal}
						/>
					</div>
					<div className="flex flex-col p-4 text-start sm:w-2/5 lg:w-3/5">
						<div className="flex flex-col">
							<h2 className="text-xl font-bold text-gray-900 md:text-2xl lg:text-4xl">
								{title}
							</h2>

							<p className="mt-4 mb-5 max-w-md text-gray-500">
								{desc}
							</p>

							<div className="flex flex-col gap-2">
								<p className="text-[#FF0049] gap-1 underline">
									{listTitle}
								</p>

								<div className="flex flex-col gap-1">
									<ul>
										{servicesList?.map((lists, index) => (
											<h4
												key={index}
												className="flex items-center"
											>
												<span>
													<BiChevronRight />
												</span>{" "}
												{lists}
											</h4>
										))}
									</ul>
								</div>
							</div>

							{title === "STUDIO RENTAL" && (
								<div className="flex mt-5">
									<RentStudioButton />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* <div className="m-10 mx-auto max-w-screen-lg overflow-hidden rounded-xl  md:pl-8 flex flex-col gap-20 relative">
        <div
          className={`flex flex-col overflow-hidden bg-white gap-4 my-4 px-4 sm:${
            flexDirection ? "flex-row-reverse" : "flex-row"
          }`}
        >
          <div className="order-first ml-auto h-48 w-full sm:order-none sm:h-auto sm:w-1/2 lg:w-2/5 px-4">
            <img
              className="h-full order-1 w-full object-cover rounded-2xl"
              src={image[0]}
              loading="lazy"
              alt=""
              onClick={openModal}
            />
          </div>
          <div className="flex flex-col w-full p-4 text-start sm:w-2/5 lg:w-3/5">
            <div className="flex flex-col  justify-center ">
              <h2 className="text-xl font-bold text-gray-900 md:text-2xl lg:text-4xl">
                {title}
              </h2>

              <p className="mt-4 mb-5 max-w-md text-gray-500">{desc}</p>

              <div className="flex flex-col gap-2">
                <p className="text-[#FF0049] flex items-center gap-1 underline">
                  {listTitle}
                </p>

                <div className="flex flex-col gap-1">
                  <ul>
                    {servicesList?.map((lists, index) => (
                      <h4 key={index} className="flex items-center">
                        <span>
                          <BiChevronRight />
                        </span>{" "}
                        {lists}
                      </h4>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
			{modalOpen && <ImageModal images={image} onClose={closeModal} />}
		</div>
	);
};

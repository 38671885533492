import React from "react";

export const SmallServiceCard = ({ title, icon }) => {
	return (
		<div className="flex flex-col items-center  hover:shadow-lg hover:border-gray-100 hover:bg-opacity-50  p-4 m-4 sm:p-8 sm:m-8">
			<span className=" text-3xl md:text-5xl text-[#171640] mb-3">
				{icon}
			</span>
			<p className="text-sm uppercase">{title}</p>
		</div>
	);
};

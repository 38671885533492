import React from "react";

export default function Map() {
  const googleMapsEmbedUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3102.7697478312594!2d-77.05240119999999!3d39.009417799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c8d4951a5cc9%3A0xa3c1ed59d127c93b!2s2426%20Linden%20Ln%2C%20Silver%20Spring%2C%20MD%2020910%2C%20USA!5e0!3m2!1sen!2s!4v1634126518364!5m2!1sen!2s";
  return (
    <div>
      <div className="w-full">
        {/* <img
          className="my-10 w-full justify-center sm:w-[600px] md:w-[825px] lg:w-[1000px] mx-auto"
          src={Map1}
          alt=""
        /> */}
        <iframe
          className="my-10 w-full justify-center sm:w-[600px] md:w-[825px] lg:w-[1000px] mx-auto"
          title="Google Maps Location"
          width="600"
          height="450"
          loading="lazy"
          src={googleMapsEmbedUrl}
        ></iframe>
      </div>
    </div>
  );
}

import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Homepage from "./pages/Home/Homepage";
import OurServices from "./pages/OurServices";
import Footer from "./components/Footer";
import Portfolio from "./pages/Portfolio";
import "./App.css";
import "@fontsource-variable/rubik";
import ContactUs from "./pages/ContactUs";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

function App() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "auto" });
	}, [pathname]);

	return (
		<div className="App">
			<Toaster />
			<Navbar />
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/services" element={<OurServices />} />
				<Route path="/portfolio" element={<Portfolio />} />
				<Route path="/contactus" element={<ContactUs />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;

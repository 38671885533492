import {
	FaAudioDescription,
	FaFileWaveform,
	FaFolder,
	FaCameraRetro,
	FaVideo,
	FaHouse,
	FaChartLine,
	FaPenFancy,
	FaPhotoFilm,
} from "react-icons/fa6";
import {
	AdVideos,
	Audio,
	Content,
	Digital,
	Media,
	Photography,
	StudioRental,
	Video,
} from "../assets";
import { BiChevronRight } from "react-icons/bi";

export const servicesData = [
	{
		title: "AUDIO - VISUAL(AV)",
		desc: `   We offer equipment solutions for video, lighting, and sound that
              improve your event's entire experience. We have the know-how to
              make your event unique thanks to our in-depth experience in sound
              and lighting engineering`,
		listTitle: "Our Audiovisual solutions",
		servicesList: [
			"Equipment rental",
			"Consulti",
			" Installation",
			"Production",
			"Live event production",
		],
		image: [Audio, Audio, Audio],

		icon: <FaFileWaveform />,
	},
	{
		title: "DIGITAL MARKETING",
		desc: `     Our digital marketing team specializes in offering customized
              digital marketing solutions to help our clients reach their target
              audience and optimize their online presence. Whether you need
              search engine optimization (SEO), pay-per-click (PPC) advertising,
              or social media marketing, we can help you make the most of your
              digital marketing efforts.`,
		listTitle: " Our digital marketing services",

		image: [Digital, Digital, Digital],
		servicesList: [
			"Content marketing",
			"Email Marketing",
			"Influencer Marketing",
			"per-click Ads",
			"Search engine Marketing",
			"Social media marketing",
		],
		icon: <FaChartLine />,
	},
	{
		title: "CONTENT CREATION",
		desc: ` We believe that high-quality content is key to success in today's
              market, which is why our team of expert content creators focuses
              on producing engaging, informative, and valuable content that
              resonates with your target audience. From blog posts and articles
              to video scripts and podcasts, we can create custom content that
              aligns with your brand values and goals.`,
		listTitle: "  Our content creation services",

		image: [Content, Content, Content],
		servicesList: [
			"Video content creation",
			"Copywriting",
			"Video content creation",
			"Web content writing",
		],
		icon: <FaPenFancy />,
	},

	// {
	//   title: "CONTENT MANAGEMENT",
	//   icon: <FaFolder />,
	// },
	{
		title: "MEDIA PRODUCTION",
		listTitle: "Our media production services",
		desc: `Our media production team brings your content to life with
              visually stunning, professional-quality multimedia assets. From
              video production and animation to graphic design and photography,
              we provide end-to-end media production services that help you
              showcase your brand to the world.`,

		image: [Media, Media, Media],
		servicesList: [
			"Video production",
			"Film production",
			"  Photography",
			" Photography",
		],
		icon: <FaPhotoFilm />,
	},
	{
		title: "AD VIDEOS",
		listTitle: "Our ad video services",
		desc: `Our video production team has years of expertise in making
              appealing and successful advertisements for our clients. We ensure
              that your brand message is presented in the most engaging way
              possible, from conception to implementation. We employ
              cutting-edge technology and strategies to create high-quality
              videos that are appealing to your target audience`,

		image: [AdVideos, AdVideos, AdVideos],
		servicesList: [
			"Explainer videos",
			"Promotional videos",
			"Testimonial videos",
			"Case study videos",
			"How-to videos",
			"Behind-the-scenes videos",
		],
		icon: <FaAudioDescription />,
	},
	{
		title: "PHOTOGRAPHY",
		listTitle: "Our photography services",
		desc: `Our professional photographers specialize in capturing those
              priceless moments that you want to remember for the rest of your
              life. We provide a variety of packages spanning from portrait
              photography to product photography, ensuring that we meet all of
              your requirements.`,

		image: [Photography, Photography, Photography],
		servicesList: [
			" Wedding photography",
			"Event Photography",
			"Product photography",
			"Graduation photography",
			"Music videography",
		],
		icon: <FaCameraRetro />,
	},
	{
		title: "VIDEOGRAPHY",
		listTitle: "Our ad video services",
		desc: `   Our videography services cater to a wide range of categories
              including event coverage, commercial videos, and social media
              campaigns. Our team of experts is passionate about their craft and
              committed to producing content that truly represents your brand.`,

		image: [Video, Video, Video],
		servicesList: [
			"Event videography",
			"Corporate Videography",
			"Wedding Videography",
			"Product videography",
			"Documentary videography",
		],
		icon: <FaVideo />,
	},
	{
		title: "STUDIO RENTAL",
		listTitle: "Our ad video services",
		desc: ` Our premium studio is built to meet all of your needs, whether
              they be for product photography, video shoots, or podcasts. The
              studio is outfitted with cutting-edge technology and a dedicated
              crew to ensure that everything goes smoothly`,

		image: [StudioRental, StudioRental, StudioRental],
		servicesList: [
			"Event videography",
			"Corporate Videography",
			"Wedding Videography",
			"Product videography",
			"Documentary videography",
		],
		icon: <FaHouse />,
	},
];
